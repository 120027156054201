@media (max-width: 599px) {
  .demo_container {
    position: relative;
    display: block;
    margin-top: -57px;
    height: 1000px;
  }
  .bottom_right_image {
    position: absolute;
    transform: translate(0%, 26px);
    height: 350px;
  }
  .demo_rectangle {
    position: absolute;
    transform: translate(-50%, 250px);
    left: 50%;
    width: 350px;
    height: 500px;
    background: linear-gradient(
      to right,
      rgba(27, 32, 45, 1),
      rgba(10, 12, 20, 1)
    );
    border-radius: 20px;
    top: 0%;
  }
  .demo_image {
    position: absolute;
    transform: translate(-50%, 45%);
    left: 50%;
    width: 350px;
    margin: 250px auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .demo_header {
    position: absolute;
    transform: translate(-50%, 260px);
    left: 50%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #ffffff;
    text-align: left;
  }
  .demo_text {
    position: absolute;
    left: 50%;
    top: 280px;
    transform: translate(-70%, 44%);
    max-width: 200px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: left;
  }
}

@media (min-width: 600px) and (max-width: 1399px) {
  .demo_container {
    position: relative;
    display: block;
    margin-top: 70px;
    height: 1100px;
  }
  .bottom_right_image {
    position: absolute;
    transform: translate(40%, -120px);
    height: 450px;
  }
  .demo_rectangle {
    position: absolute;
    transform: translate(-50%, 6%);
    left: 50%;
    width: 550px;
    height: 600px;
    background: linear-gradient(
      to right,
      rgba(27, 32, 45, 1),
      rgba(10, 12, 20, 1)
    );
    border-radius: 20px;
    top: 0%;
  }
  .demo_image {
    position: absolute;
    transform: translate(-50%, 15%);
    left: 50%;
    width: 550px;
    margin: 150px auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .demo_header {
    position: absolute;
    transform: translate(-50%, 0%);
    top: 6%;
    left: 50%;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #ffffff;
    text-align: left;
  }
  .demo_text {
    position: absolute;
    left: 50%;
    transform: translate(-230px, 120px);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: left;
  }
}

@media (min-width: 1400px) {
  .demo_container {
    position: relative;
    display: block;
    margin-top: -57px;
    height: 1000px;
  }
  .bottom_right_image {
    position: absolute;
    transform: translate(15%, 0);
    height: 1000px;
  }
  .demo_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 1200px;
    height: 650px;
    background: linear-gradient(
      to right,
      rgba(27, 32, 45, 1),
      rgba(10, 12, 20, 1)
    );
    border-radius: 40px;
    margin: 200px auto;
  }
  .demo_image {
    position: absolute;
    transform: translate(0, 0);
    left: 50%;
    width: 650px;
    margin: 230px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .demo_header {
    position: absolute;
    top: 220px;
    left: 50%;
    transform: translate(-300px, 0);
    max-width: 1000px;

    font-family: Roboto, sans-serif;
    font-size: 24px;
    line-height: 1.25;
    color: #ffffff;
  }
  .demo_text {
    position: absolute;
    top: 420px;
    left: 50%;
    transform: translate(-500px, 0);
    max-width: 1000px;

    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 2;
    color: #d4d4d4;
    text-align: left;
  }
}
