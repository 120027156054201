.header_container {
  position: relative;
  display: inline-block;
}

@media (max-width: 599px) {
  .header_image {
    width: 300px;
    transform: scaleY(4);
  }
  .header_components {
    position: absolute;
    top: 40%;
    left: 5%;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    color: #00ff85;
    line-height: 1.5;
  }
  .header_text {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    line-height: 1.25;
    color: #ffffff;
    margin: 65px auto;
    max-width: 300px;
    font-weight: bold;
  }
}

@media (min-width: 600px) and (max-width: 1399px) {
  .header_image {
    width: 550px;
    transform: scaleY(2);
  }
  .header_components {
    position: absolute;
    top: 0%;
    left: 5%;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #00ff85;
    line-height: 1.5;
  }
  .header_text {
    font-family: Roboto, sans-serif;
    font-size: 36px;
    line-height: 1.25;
    color: #ffffff;
    margin: 30px auto;
    max-width: 500px;
    font-weight: bold;
  }
}

@media (min-width: 1400px) {
  .header_image {
    width: 1300px;
  }
  .header_components {
    position: absolute;
    top: 8%;
    left: 5%;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    color: #00ff85;
    line-height: 1.5;
  }
  .header_text {
    font-family: Roboto, sans-serif;
    font-size: 48px;
    line-height: 1.25;
    color: #ffffff;
    margin: 50px auto;
    max-width: 650px;
    font-weight: bold;
  }
}
