.sign_up_container {
  position: relative;
  display: block;
}

@media (max-width: 599px) {
  .sign_up_container {
    position: relative;
    display: block;
    height: 650px;
  }
  .bottom_left_image {
    position: absolute;
    transform: translate(-75%, 0%);
    height: 650px;
  }
  .sign_up_quest_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 350px;
    height: 120px;
    background: #1b202d;
    border-radius: 20px;
    margin: 50px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .sign_up_quest_text {
    position: absolute;
    top: 15%;
    left: 0%;
    transform: translate(10%, 0);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 300px;
  }
  .sign_up_quest_text_cent {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_quest_button_yes {
    position: absolute;
    transform: translate(-103%, -200%);
    top: 0%;
    left: 50%;
    width: 130px;
    height: 30px;
    background-color: #5648a7;
    border-radius: 5px;
    margin: 130px auto;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_quest_button_no {
    position: absolute;
    transform: translate(3%, -200%);
    top: 0%;
    left: 50%;
    width: 130px;
    height: 30px;
    background-color: #5648a7;
    border-radius: 5px;
    margin: 130px auto;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_quest_button_text {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -20%);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_email_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 350px;
    height: 190px;
    background: #1b202d;
    border-radius: 20px;
    margin: 200px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .sign_up_email_text {
    position: absolute;
    top: 10%;
    left: 0%;
    transform: translate(8%, 0);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 300px;
  }
  .sign_up_email_text_cent {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 300px;
  }
  .sign_up_email_form {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 40%;
    width: 250px;
    height: 30px;
    background: #d4d4d4;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    padding-left: 20px;
    box-shadow: none;
    border: none;
    outline: none;
  }
  .sign_up_email_button {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 63%;
    width: 277px;
    height: 30px;
    background-color: #5648a7;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_email_button_text {
    position: absolute;
    left: 50%;
    top: -20%;
    transform: translate(-50%, 0%);
    font-family: Roboto, sans-serif;
    color: #d4d4d4;
    font-size: 12px;
  }
  .sign_up_email_button:active {
    background-color: #463a8c;
  }
  .sign_up_quest_button_no:active {
    background-color: #463a8c;
  }
  .sign_up_quest_button_yes:active {
    background-color: #463a8c;
  }
}

@media (min-width: 600px) and (max-width: 1399px) {
  .sign_up_container {
    position: relative;
    display: block;
    height: 800px;
  }
  .bottom_left_image {
    position: absolute;
    transform: translate(-75%, 0%);
    height: 800px;
  }
  .sign_up_quest_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 530px;
    height: 150px;
    background: #1b202d;
    border-radius: 30px;
    margin: 90px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .sign_up_quest_text {
    position: absolute;
    top: 20%;
    left: 0%;
    transform: translate(10%, 0);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_quest_text_cent {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_quest_button_yes {
    position: absolute;
    transform: translate(-103%, -110%);
    top: 0%;
    left: 50%;
    width: 200px;
    height: 40px;
    background-color: #5648a7;
    border-radius: 7px;
    margin: 130px auto;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_quest_button_no {
    position: absolute;
    transform: translate(3%, -110%);
    top: 0%;
    left: 50%;
    width: 200px;
    height: 40px;
    background-color: #5648a7;
    border-radius: 7px;
    margin: 130px auto;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_quest_button_text {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_email_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 530px;
    height: 220px;
    background: #1b202d;
    border-radius: 30px;
    margin: 300px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .sign_up_email_text {
    position: absolute;
    top: 15%;
    left: 0%;
    transform: translate(4%, 0);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 500px;
  }
  .sign_up_email_text_cent {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 300px;
  }
  .sign_up_email_form {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 40%;
    width: 450px;
    height: 40px;
    background: #d4d4d4;
    border-radius: 7px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-left: 20px;
    box-shadow: none;
    border: none;
    outline: none;
  }
  .sign_up_email_button {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 67%;
    width: 476px;
    height: 40px;
    background-color: #5648a7;
    border-radius: 7px;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_email_button_text {
    position: absolute;
    left: 50%;
    top: -10%;
    transform: translate(-50%, 0%);
    font-family: Roboto, sans-serif;
    color: #d4d4d4;
    font-size: 14px;
  }
  .sign_up_email_button:active {
    background-color: #463a8c;
  }
  .sign_up_quest_button_no:active {
    background-color: #463a8c;
  }
  .sign_up_quest_button_yes:active {
    background-color: #463a8c;
  }
}

@media (min-width: 1400px) {
  .sign_up_container {
    position: relative;
    display: block;
    height: 1050px;
  }
  .bottom_left_image {
    position: absolute;
    transform: translate(-75%, -10%);
    height: 1160px;
  }
  .sign_up_quest_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 700px;
    height: 250px;
    background: #1b202d;
    border-radius: 40px;
    margin: 120px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .sign_up_quest_text {
    position: absolute;
    top: 20%;
    left: 0%;
    transform: translate(10%, 0);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_quest_text_cent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_quest_button_yes {
    position: absolute;
    transform: translate(-103%, 0);
    left: 50%;
    width: 300px;
    height: 50px;
    background-color: #5648a7;
    border-radius: 10px;
    margin: 130px auto;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_quest_button_no {
    position: absolute;
    transform: translate(3%, 0);
    left: 50%;
    width: 300px;
    height: 50px;
    background-color: #5648a7;
    border-radius: 10px;
    margin: 130px auto;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_quest_button_text {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
  }
  .sign_up_email_rectangle {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    width: 700px;
    height: 300px;
    background: #1b202d;
    border-radius: 40px;
    margin: 400px auto;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .sign_up_email_text {
    position: absolute;
    top: 15%;
    left: 0%;
    transform: translate(8%, 0);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 600px;
  }
  .sign_up_email_text_cent {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #d4d4d4;
    text-align: center;
    max-width: 300px;
  }
  .sign_up_email_form {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 40%;
    width: 560px;
    height: 50px;
    background: #d4d4d4;
    border-radius: 10px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    padding-left: 20px;
    box-shadow: none;
    border: none;
    outline: none;
  }
  .sign_up_email_button {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 63%;
    width: 585px;
    height: 50px;
    background-color: #5648a7;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .sign_up_email_button_text {
    position: absolute;
    left: 50%;
    top: -10%;
    transform: translate(-50%, 0%);
    font-family: Roboto, sans-serif;
    color: #d4d4d4;
    font-size: 18px;
  }
  .sign_up_email_button:active {
    background-color: #463a8c;
  }
  .sign_up_quest_button_no:active {
    background-color: #463a8c;
  }
  .sign_up_quest_button_yes:active {
    background-color: #463a8c;
  }
}
