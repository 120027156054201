.chip_container {
  position: relative;
  display: inline-block;
}

@media (max-width: 599px) {
  .chip_image {
    width: 320px;
  }
  .chip_text {
    position: absolute;
    top: 30%;
    left: 22%;
    max-width: 180px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    color: #ffffff;
  }
}

@media (min-width: 600px) and (max-width: 1399px) {
  .chip_image {
    width: 550px;
  }
  .chip_text {
    position: absolute;
    top: 34%;
    left: 125px;
    max-width: 300px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #ffffff;
  }
}

@media (min-width: 1400px) {
  .chip_image {
    width: 1400px;
  }
  .chip_text {
    position: absolute;
    top: 33%;
    left: 37%;
    max-width: 350px;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    line-height: 1.25;
    color: #ffffff;
  }
}
