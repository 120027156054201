@media (max-width: 599px) {
  .refs_container {
    position: relative;
    display: block;
    height: 0px;
  }
  .refs_text {
    position: absolute;
    top: -130px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: #d4d4d4;
  }
  .refs_image {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40px;
  }
}

@media (min-width: 600px) and (max-width: 1399px) {
  .refs_container {
    position: relative;
    display: block;
    height: 0px;
  }
  .refs_text {
    position: absolute;
    top: -130px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #d4d4d4;
  }
  .refs_image {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40px;
  }
}

@media (min-width: 1400px) {
  .refs_container {
    position: relative;
    display: block;
    height: 0px;
  }
  .refs_text {
    position: absolute;
    top: -170px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    color: #d4d4d4;
  }
  .refs_image {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 70px;
  }
}
